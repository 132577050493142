// mixins
@use "sass:math";
// 链接动画
@mixin dz($time: 0.4s) {
  transition: all $time ease;
}

// 文字描边
@mixin ts($s1: 1px, $s2: 1px, $color: $cff) {
  text-shadow: $s1 $s1 $s2 $color, -$s1 $s1 $s2 $color, $s1 (-$s1) $s2 $color, -$s1 (-$s1) $s2 $color;
}
//背景渐变
@mixin jb($color1: #000, $color2: #fff, $deg: 0) {
  background-image: linear-gradient($deg + deg, $color2, $color1);
}
// 文字渐变
@mixin wzjb($color1: #000, $color2: #fff, $deg: 0) {
  @include jb($color1, $color2, $deg);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
// 旋转
@mixin xz($deg: 360) {
  transform: rotate($deg + deg);
}
// 旋转放大
@mixin xzfd($deg: 360, $s1: 1.2) {
  transform: rotate($deg + deg) scale($s1);
}
// 旋转放大
@mixin fd($s1: 1.2) {
  transform: scale($s1);
}
// 移动动画
@mixin yd($s1: 0, $s2: 0) {
  transform: translate($s1, $s2);
}
// 禁止选中
@mixin ns {
  user-select: none;
}
// 一行文字标题
@mixin oneline($s1) {
  overflow: hidden;
  line-height: $s1;
  height: $s1;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin moreLine($line, $height) {
  height: $height;
  line-height: math.div($height, $line);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  text-overflow: ellipsis;
}
@mixin moreZoomLine($line, $height) {
  max-height: $height;
  line-height: math.div($height, $line);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  text-overflow: ellipsis;
}
// 计算
@mixin calc($property, $expression) {
  #{$property}: calc(#{$expression});
}
@mixin hlh($s1) {
  height: $s1;
  line-height: $s1;
  overflow: hidden;
}
@mixin mh($px: 10px) {
  backdrop-filter: blur($px);
}

@function rem($px) {
  // $px * 375 / 75000
  @return ($px * 0.0625) + rem;
}

@function res($url) {
  @return ($res + $url);
}

@mixin gray {
  filter: grayscale(100%);
}

@mixin animation-keyframes {
  $animation-name: unique-id();
  animation-name: $animation-name;

  @keyframes #{$animation-name} {
    @content;
  }
}
