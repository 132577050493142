$bg0: #0e0c15;
$bg: #151515;
$dark1: #303044;
$dark2: #212121;
$dark3: #151515;
$dark4: #1a1919;
$line: #252525;
$line2: #2c2c2c;

$text1: #fdfdfd;
$text2: #8d8d8d;
$text3: #b9b9b9;
$text4: #eeac48;
$text5: #49495a;

$blue: #2a85ff;
$green: #36c78a;
$red: #f46778;
$purple: #8e59ff;
$yellow: #eeac48;
$cyan: #88fff8;
$orange: #f37021;

$l-blue: #b1e5fc;
$ll-blue: #a2add5;
$l-green: #b5e4ca;
$l-orange: #fd9353;
$l-purple: #cabdff;
$l-yellow: #ffd88d;

.rise {
  color: $green;
}
.fall {
  color: $red;
}
.warn {
  color: $yellow;
}
// 主色
$primary: $blue;
