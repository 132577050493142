.c-dropdown,
.mc-dropdown {
  position: relative;
  &-label {
    color: $text2;
    border: none;
    cursor: pointer;
    // font-weight: bold;
    display: flex;
    align-items: center;
    position: relative;
    height: 32px;
    padding: 0 26px 0 16px;
    font-size: 13px;
    background: none;
    @include dz();
    &-arrow {
      position: absolute;
      right: 12px;
      display: block;
      width: 6px;
      height: 4px;
      background: url($res + 'icon/arrow-down-mini.svg') center/contain no-repeat;
      @include dz();
    }
    img {
      margin-right: 5px;
      height: 16px;
      width: 16px;
    }
    &-delete-later {
      padding: 0 16px 0 16px;
      cursor: default;
    }
  }
  &-type-button &-label {
    background: $dark2;
    border-radius: 6px;
    box-shadow: 0 1px 10px 0 rgb($black, 0.1), 0 2px 15px 0 rgb($black, 0.05);
  }

  &.show &-label {
    &-arrow {
      @include xz(180);
    }
  }
  &.show &-type-button &-label {
    background: $dark1;
  }

  &-layout {
    position: relative;
  }
  &-menu {
    position: absolute;
    right: 0;
    top: 40px;
    background: $dark1;
    border-radius: 12px;
    overflow: hidden;
    z-index: 10;
    @include dz();
    &-inner {
      padding: 10px 0;
    }
    &-item {
      padding: 0 20px;
      cursor: pointer;
      opacity: 0.6;
      height: 46px;
      display: flex;
      align-items: center;
      width: 100%;
      @include dz();
      color: $text1;
      &:hover {
        color: $text1;
        opacity: 1;
      }
      &-icon {
        display: block;
        margin-right: 10px;
        width: 16px;
        height: 16px;
      }
      &.disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
      &-link-arrow {
        display: block;
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
  }
  &-line {
    height: 1px;
    background: rgba($text1, 0.05);
    width: 100%;
  }

  &.input &-label {
    height: 40px;
    font-size: 16px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    &-arrow {
      width: 8px;
      height: 8px;
      right: 12px;
    }
  }
  &.input &-menu {
    top: 48px;
  }
}

.mc-dropdown {
  &-label {
    height: rem(48);
    padding: 0 rem(20) 0 rem(12);
    font-size: rem(15);
    &-arrow {
      right: rem(12);
      width: rem(8);
      height: rem(6);
    }
    img {
      margin-right: rem(10);
      height: rem(20);
      width: rem(20);
    }
  }
  &-type-button &-label {
    background: $dark4;
    border-radius: rem(8);
  }

  &-layout {
    position: relative;
  }
  &-menu {
    position: static;
    background: none;
    border-radius: 0;
    &-inner {
      padding: 0 0;
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 2px;
        top: rem(15);
        bottom: rem(15);
        background: $line;
        position: absolute;
        left: rem(22);
      }
    }
    &-item {
      padding: 0 0 0 rem(48);
      height: rem(48);
      &.active {
        opacity: 1;
      }
      &-icon {
        margin-right: rem(10);
        height: rem(20);
        width: rem(20);
      }
      &-link-arrow {
        margin-left: rem(8);
        height: rem(20);
        width: rem(20);
      }
    }
  }
}
.mobile-mode {
  .c-dropdown {
    &-label {
      height: rem(32);
      padding: 0 rem(26) 0 rem(16);
      font-size: rem(13);
      &-arrow {
        right: rem(12);
        width: rem(8);
        height: rem(6);
      }
      img {
        margin-right: rem(10);
        height: rem(20);
        width: rem(20);
      }
    }
    &-type-button &-label {
      border-radius: rem(6);
    }

    &-menu {
      top: rem(42);
      border-radius: rem(6);
      &-inner {
        padding: rem(8) 0;
      }
      &-item {
        padding: 0 rem(20);
        font-size: rem(14);
        height: rem(32);
        &-icon {
          margin-right: rem(10);
          height: rem(16);
          width: rem(16);
        }
        &-link-arrow {
          margin-left: rem(8);
          height: rem(20);
          width: rem(20);
        }
      }
    }
    &-line {
      height: rem(1);
    }

    &.input &-label {
      height: rem(40);
      font-size: rem(16);
      img {
        width: rem(20);
        height: rem(20);
        margin-right: rem(10);
      }
      &-arrow {
        width: rem(8);
        height: rem(8);
        right: rem(12);
      }
    }
    &.input &-menu {
      top: rem(48);
    }
  }
}
